<template>
<div>
    <div v-show="isLoading" class="preloader"></div>
    <div class="row align-item-center">
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style="background-image: url(/images/bg/sec1-maskot.png);"></div>
        <div class="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
            <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                <div class="card-body rounded-0 text-left">
                    <div class="text-center">
                        <img :src="'/images/logo.png'" width="80" height="80" class="rounded" alt="logo">
                    </div>
                    <form @submit.prevent="onResetPassword"> 
                        <div class="form-group icon-input mb-2">
                            <i class="font-sm ti-email fw-600 text-grey-700 pr-0"></i>
                            <input disabled v-model="email" type="email" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email">                        
                        </div>
                        <div class="form-group icon-input mb-2">
                            <input :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Password" v-model="password">
                            <i class="font-sm ti-lock fw-600 text-grey-700 pr-0"></i>
                        </div>
                        <div class="form-group icon-input mb-2">
                            <input :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirm Password" v-model="password_confirmation">
                            <i class="font-sm ti-lock fw-600 text-grey-700 pr-0"></i>
                        </div>
                        <div class="form-check text-left mb-2">
                            <input type="checkbox" v-model="isVisible" class="form-check-input mt-2" id="exampleCheck2">
                            <a href="javascript:void(0)" @click="isVisible = !isVisible" class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">Show Password</a>
                        </div>
                        <div class="col-sm-12 p-0 text-left">
                            <div class="mb-1">
                                <input  type="submit" class="form-control btn-current text-center style2-input fw-600 p-0 " value="Reset Password">
                                <h6 class="text-grey-500 font-xsss fw-500 mt-4 mb-0 lh-32 text-center">Go to <router-link to="/login" class="fw-700 ml-1 text-warning">Login</router-link> Page</h6>
                            </div>
                        </div>
                    </form>
                </div>
            </div> 
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
        name: "ResetPassword",
        data() {
            return {
                email: '',
                token: '',
                password: '',
                password_confirmation: '',
                isLoading: false,
                isShow: false,
                isVisible: false,
                alertMsg: '',
                alertType: ''
            }
        },
        mounted(){
            this.email = this.$route.query.email
            this.token = this.$route.query.token
        },
        methods: {
            onResetPassword() {
                axios.post(`${process.env.VUE_APP_URL_API}/reset-password`, {
                    email: this.email,
                    token: this.token,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                }).then(response => {
                    this.alertMsg = response.data.message
                    this.$swal({
                            toast: true,
                            title: 'Reset Password Success !',
                            text: this.alertMsg,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                    })
                }).catch(error => {
                    if (error.response.status == 422) {
                        this.alertMsg = Object.values(error.response.data.errors).flat().join()
                        this.$swal({
                            toast: true,
                            title: 'Reset Password Failed !',
                            text: this.alertMsg,
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    } else if(error.response.status == 500) {
                        this.$swal({
                            toast: true,
                            title: 'Reset Password Failed !',
                            text: 'Already Reset Password !',
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            }
        }       
    }
</script>
